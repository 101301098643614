* {
  box-sizing: border-box;
}
// largeur min des dialog
.MuiDialogContent-root{
  min-width: 300px;
  @media (min-width:550px){
    min-width: 400px;
  }
  @media (min-width:860px){
    min-width: 500px;
  }
}