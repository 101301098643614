.ui-dataNutrimentLine{
  display: flex;
  flex-direction: row;
  .typo-label{
    flex: 0.3;
    font-size: 12px;
    line-height: 1;
    font-weight: bold;
  }
  .typo-ref{
    flex: 0.3;
    font-size: 12px;
    line-height: 1;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-style: italic;
  }
  .typo-value{
    flex: 0.3;
    font-size: 12px;
    line-height: 1;
    font-weight: bold;
  }
}