// DayNutrimentLine.scss
.ui-dayNutrimentLine{
  .label-wrapper {
    text-align: right;
    min-width: 80px;
    max-width: 80px;
    padding-right: 10px;
  }
  .progress-wrapper{
    flex: 1;
    position: relative;
  }
  .values-wrapper{
    padding-left: 106px;
  }

  .typo-values{
    font-size: 14;
    line-height: 1;
    font-weight: 500;
  }
}